import React, { PureComponent } from "react";
import styled from "styled-components";
import { Empresa, Imóvel } from "smart-imob-types";
import Slider from "react-slick";
import ImovelCard from "./imovel-card-v3";
import Loading from "../global-components/Loading";
import EmpresaContext from "../../context";
import { post } from "../../client_api";
import { HBT } from "../../builder-context";

interface RecentPropertyStyleProps {
  empresa: Empresa;
}

const RecentPropertyStyle = styled.div<RecentPropertyStyleProps>`
  max-width: 90%;
  padding-top: 70px;
  margin: 0 auto;
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    flex-direction: column;
    .text-container {
      .featured-property-title {
        margin-bottom: 20px;
      }
      .featured-property-subtitle {
      }
    }
    .slider-controls {
      display: flex;
      .btn-prev,
      .btn-next {
        font-size: 26px;
        padding: 0px 25px;
        border-radius: 25px;
        transition: 250ms ease;
        cursor: pointer;
        &:hover {
          color: #fff;
          background: ${props => props.empresa.palleta.cor_primaria};
          box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        }
      }
      .btn-prev {
        margin-right: 20px;
      }
    }
  }
  .featured-property-container {
    margin: 0 -15px;
  }
  @media (min-width: 576px) {
    max-width: 540px;
    .title-container {
      flex-direction: row;
    }
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1600px) {
    max-width: 1360px;
  }
`;

interface RecentPropertyProps {
  empresa?: Empresa;
}

interface RecentPropertyState {
  imoveis: Imóvel[];
  loading: boolean;
  slider: any;
}

class RecentProperty extends PureComponent<
  RecentPropertyProps,
  RecentPropertyState
> {
  private sliderLancamentos: React.RefObject<HTMLInputElement>;
  state = {
    imoveis: [],
    loading: true,
    slider: null
  };

  constructor(props) {
    super(props);
    this.sliderLancamentos = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      slider: this.sliderLancamentos
    });
    try {
      let { imoveis } = await post(this.context.url, "buscar_imoveis", {
        limit: 10
      });
      this.setState(
        {
          imoveis: imoveis,
          loading: false
        },
        () => console.log("imóveis lancamentos: ", this.state.imoveis)
      );
    } catch (error) {
      console.log("não pegou imóveis lançamentos", error);
    }
  }

  render() {
    const empresa = this.context;
    const { slider, imoveis } = this.state;
    const prev = () => {
      // @ts-ignore
      this.sliderLancamentos.slickPrev();
    };
    const next = () => {
      // @ts-ignore
      this.sliderLancamentos.slickNext();
    };
    const settings = {
      infinite: this.state.imoveis.length > 2 ? true : false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      lazyLoad: "progressive",
      arrows: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            infinite: this.state.imoveis.length > 1 ? true : false,
            slidesToShow: 2
          }
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    if (this.state.loading) {
      return <Loading />;
    }
    if (this.state.imoveis.length === 0) {
      return null;
    }
    return (
      <RecentPropertyStyle empresa={empresa}>
        <div className="title-container">
          <div className="text-container">
            <h2 className="featured-property-title">
              {empresa.frase_lancamentos
                ? empresa.frase_lancamentos
                : "Imóveis recentes"}
            </h2>
            <p
              className="featured-property-subtitle"
              {...HBT("bb_recent_property_subtitle")}
            >
              {this.context["bb_recent_property_subtitle"] ||
                "Veja nossos imóveis mais recentes"}
            </p>
          </div>
          <div className="slider-controls">
            <span className="btn-prev" onClick={() => prev()}>
              <i className="fa fa-arrow-left" />
            </span>
            <span className="btn-next" onClick={() => next()}>
              <i className="fa fa-arrow-right" />
            </span>
          </div>
        </div>
        <div className="featured-property-container">
          <Slider
            ref={slider => (this.sliderLancamentos = slider)}
            {...settings}
          >
            {imoveis.map((imovel: Imóvel) => (
              <ImovelCard key={imovel.codigo} imovel={imovel} />
            ))}
          </Slider>
        </div>
      </RecentPropertyStyle>
    );
  }
}

RecentProperty.contextType = EmpresaContext;
export default RecentProperty;
