import React, { useContext } from "react";
import Loadable from "react-loadable";
import EmpresaContext from "../context";
import Loading from "./global-components/Loading";
import Header from "./section-components/banner-select";
import RecentProperty from "./section-components/recent-property";
import RecentCondominium from "./section-components/recent-condominium";

const Navbar = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Navbar" */ "./select-components/navbar-select"),
  loading() {
    return <></>;
  }
});
const CardDisplay = Loadable({
  loader: () =>
    import(/* webpackChunkName: "card-display" */ "./section-components/card-display"),
  loading: Loading
});
const CardDisplayV2 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "card-display-v2" */ "./section-components/card-display-v2"),
  loading: Loading
});
const FinanciamentosDisplay = Loadable({
  loader: () =>
    import(/* webpackChunkName: "financiamento-card-display" */ "./section-components/financiamentos-card-display"),
  loading: Loading
});
const FAQ = Loadable({
  loader: () =>
    import(/* webpackChunkName: "faq" */ "./section-components/faq"),
  loading: Loading
});
const FeaturedProperty = Loadable({
  loader: () =>
    import(/* webpackChunkName: "featured-property" */ "./section-components/featured-property"),
  loading: Loading
});
const SellingProperties = Loadable({
  loader: () =>
    import(/* webpackChunkName: "selling-properties" */ "./section-components/selling-properties"),
  loading: Loading
});
const RentProperties = Loadable({
  loader: () =>
    import(/* webpackChunkName: "rent-properties" */ "./section-components/rent-properties"),
  loading: Loading
});
const BlogContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "blog-container" */ "./section-components/blog-container"),
  loading: Loading
});
const CityContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "city-container" */ "./section-components/city-container"),
  loading: Loading
});
const AboutV3 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "about-v3" */ "./section-components/about-v3"),
  loading: Loading
});
const VideoContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "video-container" */ "./section-components/video-container"),
  loading: Loading
});
const DepoimentosV2 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "depoimentos-container" */ "./section-components/depoimentos-container"),
  loading: Loading
});
const InstagramContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "instagram-container" */ "./global-components/instagram-container"),
  loading: Loading
});
const InstagramContainerAlt = Loadable({
  loader: () =>
    import(/* webpackChunkName: "instagram-container" */ "./global-components/instagram-container-alt"),
  loading: Loading
});
const TiktokContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "tiktok-container" */ "./global-components/tiktok-container"),
  loading: Loading
});
const YoutubeContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "youtube-container" */ "./global-components/youtube-container"),
  loading: Loading
});
const ImageContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "image-container" */ "./section-components/image-container"),
  loading: Loading
});
const Footer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "footer" */ "./select-components/footer-select"),
  loading: Loading
});
const SocialBar = Loadable({
  loader: () =>
    import(/* webpackChunkName: "social-bar" */ "./global-components/social-bar"),
  loading: Loading
});

const Home_V6 = () => {
  let context = useContext(EmpresaContext);
  return (
    <div>
      <Navbar />
      <Header />
      {context.db_id === "otFkVwGR3DAiqjkb9aeq" ||
      context.db_id === "EyZDtlq7JAr2H22dMndt" ||
      context.db_id === "wVZwL0aQRS32VlrBlVxu" ||
      context.db_id === "xv7AOd0igsRxjXBjYpgv" ? (
        <>
          <FeaturedProperty />
          {context.exibir_lancamentos && <RecentProperty />}
          {(!context.ocultar_card_display ||
            context.ocultar_card_display === undefined) && <CardDisplay />}
          {context.mostrar_card_display_v2 && <CardDisplayV2 />}
        </>
      ) : (
        <>
          {(!context.ocultar_card_display ||
            context.ocultar_card_display === undefined) && <CardDisplay />}
          <FeaturedProperty />
          {context.exibir_lancamentos && <RecentProperty />}
          {context.exibir_imoveis_venda && <SellingProperties />}
          {context.exibir_imoveis_locacao && <RentProperties />}
          {context.mostrar_card_display_v2 && <CardDisplayV2 />}
        </>
      )}
      {context.mostrar_card_financiamentos && <FinanciamentosDisplay />}
      {/* {context.exibir_lancamentos && <RecentProperty />} */}
      {/* {<RecentCondominium />} */}
      {context.info_gen &&
        (context.info_gen.city_container ||
          context.info_gen.city_container === "undefined") && <CityContainer />}
      {context.info_gen &&
        context.info_gen.video_container && <VideoContainer />}
      {(context.info_gen.home_sobre ||
        context.info_gen.home_sobre === undefined) && <AboutV3 />}
      <DepoimentosV2 />
      {/* <BlogContainer /> */}
      <InstagramContainer />
      <InstagramContainerAlt />
      <TiktokContainer />
      <YoutubeContainer />
      <ImageContainer />
      {context.faq && <FAQ />}
      <Footer />
      <SocialBar />
    </div>
  );
};

export default Home_V6;
