import React, { useContext } from "react";
import Styled from "styled-components";
import { Imóvel } from "smart-imob-types";
import { toBRL } from "../../utils/util_functions";
import { Link } from "react-router-dom";
import EmpresaContext from "../../context";

const ImovelCardStyle = Styled.div`
  margin: 30px 15px;
  .card-container {
    position: relative;
    border-radius: 15px;
    display: grid;
    grid-template-rows: 1fr auto;
    overflow: hidden;
    box-shadow: ${props =>
      props.empresa.palleta.cor_primaria + "20"} 0px 20px 25px -5px, ${props =>
  props.empresa.palleta.cor_primaria + "05"} 0px 10px 10px -5px;
    /* box-shadow: ${props =>
      props.empresa.palleta.cor_primaria + "20"} 0px 3px 8px; */
    picture {
      position: relative;
      img {
        min-width: 100%;
        /* max-height: 280px; */
        height: 280px;
        object-fit: cover;
        transition: 250ms ease;
      }
      .property-code {
        position: absolute;
        background: ${props => props.empresa.palleta.cor_primaria};
        color: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        top: 20px;
        left: 20px;
        z-index: 1;
      }
    }
    .info-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: 100%;
      padding: 20px;
      color: #1a1a1a;
      background: #fff;
      z-index: 1;
      min-height: 340px;
      .property-location {
        color: ${props => props.empresa.palleta.cor_primaria};
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        i {
          margin-right: 5px;
        }
      }
      .property-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 17px;
        color: #282828;
      }
      .property-price {
        color: ${props => props.empresa.palleta.cor_primaria};
        margin-bottom: 20px;
        font-weight: 700;
      }
      .info-list {
        display: flex;
        width: 100%;
        font-size: 12px;
        border-top: 1px solid ${props => props.empresa.palleta.cor_primaria};
        .single-info {
          padding: 20px 0;
          margin-right: 10px;
        }
      }
      .btn-property {
        background: ${props => props.empresa.palleta.cor_primaria};
        color: #fff;
        padding: 15px 20px;
        border-radius: 25px;
        margin-top: 5px;
        height: auto;
        line-height: normal;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

interface ImovelCardProps {
  imovel: Imóvel;
}

const ImovelCard: React.FC<ImovelCardProps> = props => {
  const context = useContext(EmpresaContext);
  const { imovel } = props;
  let imgSrc;
  let imgSrcWebp;
  try {
    const fotoDestaque = imovel.fotos.find(e => e.destaque === true);
    let SortedFotos = imovel.fotos
      ? imovel.fotos.sort(
          (a, b) =>
            a.destaque
              ? -1
              : parseInt(String(a.ordem)) - parseInt(String(b.ordem))
        )
      : [];
    imgSrcWebp = fotoDestaque ? fotoDestaque.source.uri_webp || "" : "";
    imgSrc = fotoDestaque
      ? fotoDestaque.resized || fotoDestaque.source.uri
      : SortedFotos[0].resized || SortedFotos[0].source.uri;
  } catch (_) {
    imgSrc =
      "https://firebasestorage.googleapis.com/v0/b/smartimob-dev-test.appspot.com/o/placeholders%2Fproperty-placeholder.png?alt=media&token=96ef2786-f47a-484c-aa76-82880bd20b01";
  }

  let link = `/imovel/${imovel.db_id}`;

  if (imovel.suítes && imovel.suítes !== "0") {
    link = "/" + imovel.suítes + "-Suite" + link;
  }
  if (imovel.vagas && imovel.vagas !== "0") {
    link = "/" + imovel.vagas + "-Vaga" + link;
  }
  if (imovel.dormitórios && imovel.dormitórios !== "0") {
    link = "/" + imovel.dormitórios + "-Dormitorio" + link;
  }
  if (imovel.banheiros && imovel.banheiros !== "0") {
    link = "/" + imovel.banheiros + "-Banheiro" + link;
  }

  if (imovel.cidade && imovel.cidade.nome) {
    link = "/" + imovel.cidade.nome.replace(/ /g, "-") + link;
  }
  if (imovel.tipo) {
    link = "/" + imovel.tipo.replace(/ /g, "-") + link;
  }

  let isVenda =
    imovel.venda &&
    typeof imovel.preço_venda === "number" &&
    imovel.preço_venda > 0;
  let isLocacao =
    imovel.locação &&
    typeof imovel.locação === "number" &&
    imovel.preço_locação > 0;
  if (isVenda && isLocacao) {
    link = "/venda_e_locacao" + link;
  } else if (isVenda) {
    link = "/venda" + link;
  } else if (isLocacao) {
    link = "/locação" + link;
  }

  const titulo = `${imovel.vendido ? "VENDIDO | " : ""}${
    imovel.cidade && imovel.bairro
      ? imovel.cidade.nome + " | "
      : imovel.cidade
        ? imovel.cidade.nome
        : ""
  } ${imovel.bairro}`;
  return (
    <ImovelCardStyle empresa={context}>
      <Link to={link}>
        <a>
          <div className="card-container">
            <picture>
              <div className="property-code">{imovel.codigo}</div>
              <source srcSet={imgSrcWebp} type="image/webp" />
              <source srcSet={imgSrc} type="image/jpeg" />
              <img src={imgSrc} alt={imovel.codigo} />
            </picture>
            <div className="info-container">
              <div className="property-location">
                <i className="fa fa-map-marker" />
                {imovel.bairro + ", " + imovel.cidade.nome}
              </div>
              <div className="property-title">{imovel.titulo || titulo}</div>
              {!!imovel.preço_venda ? (
                imovel.venda_exibir_valor_no_site === false ? (
                  <div className="property-price">Preço sob consulta</div>
                ) : !!imovel.preço_venda_desconto ? (
                  <div className="property-price">
                    {toBRL(Number(imovel.preço_venda_desconto), imovel.moeda)}
                  </div>
                ) : (
                  <div className="property-price">
                    {toBRL(Number(imovel.preço_venda), imovel.moeda)}
                  </div>
                )
              ) : null}
              {!!imovel.preço_locação ? (
                imovel.locação_exibir_valor_no_site === false ? (
                  <div className="property-price">Locação sob consulta</div>
                ) : !!imovel.preço_locação_desconto &&
                imovel.preço_locação_desconto > 0 ? (
                  <div className="property-price">
                    {toBRL(Number(imovel.preço_locação_desconto), imovel.moeda)}
                    /mês
                  </div>
                ) : (
                  <div className="property-price">
                    {toBRL(Number(imovel.preço_locação), imovel.moeda)}
                    /mês
                  </div>
                )
              ) : null}
              <ul className="info-list">
                {!!(imovel.dormitórios && imovel.dormitórios !== "0") && (
                  <li className="single-info">
                    <i className="fa fa-bed" />
                    {" " + imovel.dormitórios} Dormitórios
                  </li>
                )}
                {!!(imovel.banheiros && imovel.banheiros !== "0") && (
                  <li className="single-info">
                    <i className="fa fa-bath" />
                    {" " + imovel.banheiros} Banheiros
                  </li>
                )}
                {!!(imovel.area_privativa && imovel.area_privativa !== "0") && (
                  <li className="single-info">
                    <i className="fa fa-expand" />
                    {" " + imovel.area_privativa}
                    m²
                  </li>
                )}
              </ul>
              <Link to={link} className="btn-property btn btn-yellow">
                Ver mais detalhes
              </Link>
            </div>
          </div>
        </a>
      </Link>
    </ImovelCardStyle>
  );
};

export default ImovelCard;
