import React, { useContext } from "react";
import Loadable from "react-loadable";
import { Empresa } from "smart-imob-types";
import EmpresaContext from "../../context";
import Loading from "../global-components/Loading";

const Bannerv1 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bannerv1" */ "../section-components/banner-v1"),
  loading() {
    return <></>;
  }
});
const Bannerv2 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bannerv2" */ "../section-components/banner-v2"),
  loading() {
    return <></>;
  }
});
const Bannerv3 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bannerv3" */ "../section-components/banner-v3"),
  loading() {
    return <></>;
  }
});
const Bannerv4 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bannerv4" */ "../section-components/banner-v4"),
  loading() {
    return <></>;
  }
});
const Bannerv5 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bannerv5" */ "../section-components/banner-v5"),
  loading() {
    return <></>;
  }
});
const Bannerv6 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bannerv6" */ "../section-components/banner-v6"),
  loading() {
    return <></>;
  }
});
const Bannerv7 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "BannerV7" */ "../section-components/banner-v7"),
  loading() {
    return <></>;
  }
});
const Bannerv8 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "BannerV8" */ "../section-components/banner-v8"),
  loading() {
    return <></>;
  }
});
const Bannerv9 = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Bannerv9" */ "../section-components/banner-v9"),
  loading() {
    return <></>;
  }
});

const headerSelect = header => {
  switch (header) {
    case 0:
      return <Bannerv1 />;
    case 1:
      return <Bannerv2 />;
    case 2:
      return <Bannerv3 />;
    case 3:
      return <Bannerv4 />;
    case 4:
      return <Bannerv5 />;
    case 5:
      return <Bannerv6 />;
    case 6:
      return <Bannerv7 />;
    case 7:
      return <Bannerv8 />;
    case 8:
      return <Bannerv9 />;
    default:
      return <Bannerv2 />;
  }
};

const HeaderSelected = props => {
  let context = useContext(EmpresaContext);
  return headerSelect(context.info_gen && context.info_gen.header);
};

export default HeaderSelected;
